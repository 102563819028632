<template>
  <div class="page">
    <div class="text">
      <img src="../assets/pic/pic_default_Illustration.png" />
      <span>服务出了点小状况！</span>
    </div>
    <div class="beian">
      <span
        ><a href="https://beian.miit.gov.cn/"
          >Copyright © 2021 福州数智云控信息科技有限公司. All rights reserved
          版权所有 | 闽ICP备2021008164号</a
        ></span
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #061c36;
  .text {
    width: 400px;
    height: auto;
    margin: auto;
    img {
      width: 100%;
      height: auto;
    }
    span {
      display: block;
      width: 100%;
      margin-top: 5px;
      text-align: center;
      font-size: 26px;
      color: #ffffff;
    }
  }
  .beian{
      width: 100%;
      padding-bottom: 15px;
      text-align: center;
      position: absolute;
      bottom: 0;
      a{
          font-size: 14px;
          color: #ffffff;
      }
  }
}
</style>